import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

const About = ({ data }) => {
    
    return(
        <Layout>

<div class="cs-template-content-body">
        <div class="container-fluid cs-page-content">
            <div class="d-flex flex-column align-items-center cs-page-initial">
                <div class="heading">
                    about us
                </div>
                <div class="content">
                    Healthy, radiant skin <span>minus</span> the toxins
                </div>
                <div class="sub-content">
                    For a long time, skincare engineered by science was full of toxic chemicals. And products made from natural ingredients were perceived as less effective. But now there’s Carrot & Stick, the
                    best of both worlds. We take a tough love approach that perfectly balances the gentle nurturing of plants with the powerful results of innovative science.
                </div>
            </div>
        </div>
        <div class="container-fluid cs-col-content-img">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 cs-content-details">
                    <div class="d-flex justify-content-center">
                        <div class="content">
                            <div class="title">
                                Our <span>vision:</span> a <span>beautiful future</span> for <span>people</span> & the <span>planet</span>
                            </div>
                            <div class="body">
                                Carrot & Stick believes in making conscious choices about what we put on our bodies,
                                just as we do with what we put in them. Since our products are being absorbed down to the cellular level,
                                there really is no difference. <br/><br/>That’s why we invest unlimited time,
                                effort and funds into the research and development of our plant-powered ingredients.
                                So we don’t have to incorporate any toxins into our highly effective skincare.<br/><br/>
                                Because nothing is more important to us than the beauty and well-being of people and the planet.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/about-vision.png" class="img-fluid product"/>
                </div>
            </div>
        </div>
        <div class="container-fluid cs-col-content-img">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/images/about-promise.png" class="img-fluid product"/>
                </div>
                <div class="col-12 col-md-6 cs-content-details">
                    <div class="d-flex justify-content-center">
                        <div class="content">
                            <div class="title">
                                Our <span>promise</span> to <span>you</span>
                            </div>
                            <div class="body">
                                <div>
                                    Carrot & Stick is committed to creating plant-derived formulas that deliver extraordinary results without relying on harmful chemicals or the standard preservatives, emulsifiers and thickeners. We strive to eliminate known irritants and pollutants that can compromise skin health and contaminate the environment. Our humane approach and ethical practices mean that we never test on animals, nor do we sell our products in countries that requires such testing. That’s how Carrot & Stick ensures that looking beautiful feels just as good.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid cs-about-box">
            <div class="d-flex justify-content-center about-product-story">
                <div class="card">
                    <div class="card-body">
                        <div class="cs-content">
                            <span>The</span> Nix List
                        </div>
                        <div class="cs-sub-content">
                            Unwanted ingredients you will never find in our skincare:
                        </div>
                        <div class="cs-content-list">
                            sulfates<br/>
                            parabens<br/>
                            formaldehydes<br/>
                            phthalates<br/>
                            mineral oil<br/>
                            retinyl palmitate<br/>
                            oxybenzone<br/>
                            coal tar<br/>
                            hydroquinone<br/>
                            triclosan<br/>
                            triclocarban<br/>
                            synthetic fragrances
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid cs-img-box story">
            <div class="d-flex">
                <div class="cs-content">
                    <div class="title">
                        products
                    </div>
                    <div class="head">
                        Discover the <span>nurturing superpowers</span> of plants
                    </div>
                    <div class="overview">
                        We are committed to developing high-performance, plant-derived fornulas.
                    </div>
                    <div class="cs-btn d-flex">
                        <a href="" class="btn btn-lg d-flex justify-content-between" role="button" aria-pressed="true">
                            <span>Shop our collections</span>
                            <img src="https://www.carrotstick.com/wp-content/themes/xejic_carrot_stick/assets/icons/arrow-32-px.svg" alt="" loading="lazy"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid cs-page-content">
            <div class="d-flex flex-column align-items-center cs-page-initial">
                <div class="heading">
                    bundles
                </div>
                <div class="content">
                    Our <span>products are designed</span> to <span>work in synergy</span> to <span>address</span> your skin concerns.
                </div>
            </div>
        </div>
  
        <div class="container-fluid cs-bundle-box">
            <section id="wrap-insta-feed" class="cf">
                <div class="instagram-hashtag-title">
                    <h3></h3>
                    <p class="instadescDesktop">Show us how you use our products in your skincare routine<br/>and tag #carrotandstickofficial for your chance to be featured!</p>
                    <p class="instadescMobile">Show us how you are using our products & tag #carrotandstickofficial for a chance to be featured!</p>
                    <div class="igfeeds-custom">
                        
                    </div>
                    
                </div>
            </section>
        </div>
    </div>
    </Layout>
    );
}

export default About